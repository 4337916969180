module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://starter.gatsby-wp.com/graphql","verbose":true,"excludeFieldNames":["blocksJSON","saveContent"],"html":{"imageQuality":60,"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"createStaticFiles":true},"presets":[{"presetName":"DEVELOP","options":{"type":{"__all":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true}}]},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-theme-blog-data/gatsby-browser.js'),
      options: {"plugins":[],"addColorModes":true,"skipTitle":[],"animation":true,"layoutWidth":{"page":"lg","post":"lg","archive":"md"},"siteUrl":"https://example.com/"},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-plugin-wp-seo/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://example.com/"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-plugin-wordpress-lightbox/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.1,"once":true,"rootMargin":"0% 50%","animateClassName":"sal-animate","selector":"[data-sal]"},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-theme-wp-starter/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://example.com/","layoutWidth":{"page":"lg","post":"lg","archive":"md"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
